/* eslint-disable react-hooks/exhaustive-deps */
import Head from 'next/head';
import React from 'react';
import { useSelector } from 'react-redux';
import { Store } from '../../store';

const HeadMeta = () => {
  const { data } = useSelector(Store.seo.selectors.get);

  return (
    <Head>
      <meta name="theme-color" content="#ff6daa" />
      <meta name="viewport" content="width=device-width, height=device-height, initial-scale=1" />
      <meta property="fb:app_id" content="519642741544020" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@goroadgoat" />
      <link rel="apple-touch-icon" href="/images/shared/logos/padded-goat-logo.png" />

      <meta name="application-name" content="Roadgoat" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="apple-mobile-web-app-title" content="Roadgoat" />
      <meta name="format-detection" content="telephone=no" />
      <meta name="mobile-web-app-capable" content="yes" />

      <title>{data.title}</title>
      <meta name="description" content={data.metadata_description} />
      <meta property="og:url" content={data.metadata_canonical_url} />
      <meta property="og:title" content={data.og_meta_title} />
      <meta property="og:description" content={data.og_meta_description} />
      <meta property="og:image" content={data.og_meta_image} />
      <meta property="og:image:width" content={data.og_meta_image_width} />
      <meta property="og:image:height" content={data.og_meta_image_height} />
      <meta property="og:type" content={data.og_type} />
      <link href={data.metadata_canonical_url} rel="canonical" />
      {data.json_ld && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `${data.json_ld}`,
          }}
        />
      )}
    </Head>
  );
};

export default HeadMeta;
