import React, { useEffect } from 'react';
import { removeCookie } from '../../utils/cookie';
import { useToasts } from 'react-toast-notifications';

const FlashMessages = ({ flashMessage }) => {
  const { addToast } = useToasts();

  useEffect(() => {
    if (flashMessage) {
      addToast(flashMessage.message, {
        appearance: flashMessage.type,
        autoDismiss: true,
      });
      removeCookie('flash_message');
    }
  }, [flashMessage]);

  return null;
};

export default FlashMessages;
