// pages/404.js
import React from 'react';

const Custom404 = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        backgroundColor: '#ff87d2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <a href="/">
        <img
          data-src="/images/pages/error-pages/error_404.jpg"
          alt="404 Error"
          style={{ width: '70%', position: 'relative', left: '15%' }}
          className="lazyload img-res"
        />
      </a>
    </div>
  );
};

export default Custom404;
