/* eslint-disable react/prop-types */
import { RewriteFrames } from '@sentry/integrations';
// import * as Sentry from '@sentry/node';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import _get from 'lodash/get';
import _isUndefined from 'lodash/isUndefined';
// import '../antd-custom.less';
import 'mapbox-gl/dist/mapbox-gl.css';
import withRedux from 'next-redux-wrapper';
import NextApp from 'next/app';
import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import Router from 'next/router';
import React from 'react';
import Div100vh from 'react-div-100vh';
import { Provider } from 'react-redux';
import { DefaultToast, ToastProvider } from 'react-toast-notifications';
import { ThemeProvider } from 'styled-components';
import { initializeHttpClient } from '../api';
import FlashMessages from '../components/FlashMessages';
import HeadMeta from '../components/HeadMeta';
import { MediaContextProvider } from '../components/media';
import NotFoundError from '../errors/NotFoundError';
import UnauthorizedError from '../errors/UnauthorizedError';
import { Store, storeConfig } from '../store';
import { GlobalStyle, RGTheme } from '../styles';
import { NotificationIcon } from '../styles/common';
import { getCookie, removeCookie } from '../utils/cookie';
import env from '../utils/environment';
import { getAuthTokenInfo } from '../utils/getUserInfo';
import PageNotFound from './404';
const WebSocket = dynamic(() => import('../components/WebSocket'), {
  ssr: false,
});

export const MyCustomToast = ({ children, ...props }) => (
  <DefaultToast {...props} style={{ minHeight: '56px' }}>
    {props.icon && <NotificationIcon data-src={props.icon} className="lazyload" />} {children}
  </DefaultToast>
);

const config = getConfig();
const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: !!process.env.NEXT_PUBLIC_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing(),
      new RewriteFrames({
        iteratee: frame => {
          frame.filename = frame.filename.replace(distDir, 'app:///_next');
          return frame;
        },
      }),
    ],
    tracesSampleRate: 1.0,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
  });
}

if (!env.isServerSide()) {
  require('lazysizes');
  require('lazysizes/plugins/attrchange/ls.attrchange');
}

class App extends NextApp {
  static async getInitialProps({ Component, ctx }) {
    let pageProps = {};

    initializeHttpClient(ctx);

    const tokenInfo = getAuthTokenInfo(ctx);
    const isAuthenticated = !_isUndefined(tokenInfo.id);

    const validPage = path => !['/404', '/500'].includes(path);
    if (validPage(ctx.pathname)) {
      await ctx.store.dispatch(Store.auth.actions.setTokenInfo(tokenInfo));
    }

    const loggedUser = () => Store.users.selectors.getLoggedUser()(ctx.store.getState());
    pageProps.currentUser = loggedUser();

    try {
      if (isAuthenticated && _isUndefined(pageProps.currentUser)) {
        await ctx.store.dispatch(Store.users.actions.fetchProfile());
        pageProps.currentUser = loggedUser();
      }

      // restricted pages
      if (Component.restricted && !isAuthenticated) {
        ctx.res.redirect('/users/login');
        return;
      }

      if (Component.getInitialProps) {
        const customContext = {
          tokenInfo,
          isAuthenticated,
          ...ctx,
        };
        const componentPageProps = await Component.getInitialProps(customContext, pageProps);
        if (componentPageProps) {
          pageProps = { ...pageProps, ...componentPageProps };
        }
      }

      const pageId = Component.pageId;
      if (pageId) {
        let metaParams = {};

        if (Component.getMetaParams) {
          const selector = selectorFn => selectorFn(ctx.store.getState());
          const dispatch = ctx.store.dispatch;
          metaParams = await Component.getMetaParams({ context: ctx, selector, dispatch });
        }

        await ctx.store.dispatch(Store.seo.actions.getObject(pageId, ctx.asPath, metaParams));
      }

      const userAgent = _get(ctx, 'req.headers.user-agent', '');
      pageProps.robot = !!userAgent.match(/(Googlebot|Lighthouse)/);

      const rawFlashMessage = getCookie('flash_message', ctx);
      if (rawFlashMessage) {
        pageProps.flashMessage = JSON.parse(rawFlashMessage);
      }

      return { pageProps };
    } catch (err) {
      if (err instanceof UnauthorizedError) {
        console.error('UnauthorizedError', err); // eslint-disable-line no-console
        removeCookie('fullToken', ctx);

        const loginPath = '/users/login';
        if (ctx.isServer) {
          ctx.res.redirect(loginPath);
        } else {
          Router.push(loginPath);
        }
        return;
      }

      if (err instanceof NotFoundError) {
        pageProps.errorStatusCode = 404;
        return { pageProps };
      }

      throw err;
    }
  }

  render() {
    const { Component, pageProps, store } = this.props;

    if (pageProps.errorStatusCode && pageProps.errorStatusCode === 404) {
      return <PageNotFound />;
    }

    return (
      <MediaContextProvider>
        <Provider store={store}>
          <ThemeProvider theme={RGTheme}>
            <ToastProvider components={{ Toast: MyCustomToast }}>
              <FlashMessages {...pageProps} />
              <HeadMeta pageId={Component.pageId} />
              <WebSocket {...pageProps} />
              <GlobalStyle />
              <Div100vh>
                <Component {...pageProps} />
              </Div100vh>
            </ToastProvider>
          </ThemeProvider>
        </Provider>
      </MediaContextProvider>
    );
  }
}

export default withRedux(storeConfig, { debug: false })(Sentry.withProfiler(App));
